import axios from 'axios'
import { ActionItemResult } from '@/types/action'
import { AwardsJury } from '@/types/awards'

export default async function createJury({
  ...params
}: {
  name: string
}): Promise<ActionItemResult<AwardsJury>> {
  const { data } = await axios.post('/api/v1/story-jury/', params)

  return data
}
