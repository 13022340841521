import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { AwardsApplication } from '@/types/awards'

export default async function fetchAwardsApplications({
  cancelToken,
  ...params
}: ActionListPayload): Promise<ActionListResult<AwardsApplication>> {
  const { data } = await axios.get<ActionListResult<AwardsApplication>>(
    '/api/v1/article-request/',
    {
      cancelToken,
      params,
    }
  )

  return data
}
