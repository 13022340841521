import axios from 'axios'
import { ActionItemResult } from '@/types/action'
import { AwardsApplication } from '@/types/awards'

export default async function fetchAwardApplication(
  id: number
): Promise<ActionItemResult<AwardsApplication>> {
  const { data } = await axios.get(`/api/v1/article-request/${id}/`)
  return data
}
