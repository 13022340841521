import { ActionItemResult } from '@/types/action'
import { ArticleAudio } from '@/types/article'
import axios from 'axios'

export default async function editArticleAudio({
  id,
  payload,
}: {
  id: number
  payload: {
    active: number
  }
}): Promise<ActionItemResult<ArticleAudio>> {
  const { data } = await axios.put(`/api/v1/article-audio/${id}`, payload)
  return data
}
