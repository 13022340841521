import axios from 'axios'
import { AwardsJury } from '@/types/awards'
import { ActionItemResult } from '@/types/action'

export default async function editJury({
  id,
  ...payload
}: Partial<AwardsJury>): Promise<ActionItemResult<AwardsJury>> {
  const { data } = await axios.put(`/api/v1/story-jury/${id}`, payload)
  return data
}
