import {
  format,
  parse,
  addDays,
  subDays,
  addMonths,
  subMonths,
  startOfYear,
  eachDayOfInterval,
  lightFormat,
  getDay,
  isValid,
  isEqual,
  differenceInDays,
  differenceInHours,
  differenceInSeconds,
  differenceInCalendarDays,
  add,
  sub,
  compareAsc,
  getDate,
  parseISO,
  startOfWeek,
  endOfWeek,
  addWeeks,
  differenceInMonths,
  subWeeks,
  formatISO,
} from 'date-fns'

import ruLocale from 'date-fns/locale/ru'

import { utcToZonedTime, format as formatTz } from 'date-fns-tz'

export const dateToMoscowTime = (value: number | Date) => {
  return utcToZonedTime(value, 'Europe/Moscow')
}

export const formatDateToMoscowTime = (
  value: number | Date,
  format: string
) => {
  return formatTz(dateToMoscowTime(value), format, {
    timeZone: 'Europe/Moscow',
  })
}

export {
  format as formatDate,
  parse as parseDate,
  addDays as addDays,
  subDays as subDays,
  addMonths as addMonths,
  subMonths as subMonths,
  startOfYear as startOfYear,
  eachDayOfInterval as eachDayOfInterval,
  lightFormat as lightFormatDate,
  getDay as getDay,
  isValid as isValidDate,
  isEqual as isEqualDate,
  differenceInDays as differenceInDays,
  differenceInHours as differenceInHours,
  differenceInSeconds as differenceInSeconds,
  add as addDate,
  sub as subDate,
  compareAsc as compareAscDate,
  getDate as getDate,
  differenceInCalendarDays as differenceInCalendarDays,
  parseISO as parseISO,
  startOfWeek as startOfWeek,
  endOfWeek as endOfWeek,
  addWeeks as addWeeks,
  differenceInMonths as differenceInMonths,
  subWeeks as subWeeks,
  formatISO as formatISO,
  ruLocale,
}
