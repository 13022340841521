import axios from 'axios'

export default async function acceptUserModeration({
  id,
  projectId,
}: {
  id: number
  projectId: number
}) {
  await axios.post(`/api/v1/user/moderation/accept/${id}`, null, {
    headers: projectId
      ? {
          'X-Project-ID': projectId,
        }
      : undefined,
  })
}
