import { GalleryPhoto } from '@/types/galleryPhoto'
import axios from 'axios'

export default async function updateGalleryPhoto({
  id,
  photo,
}: {
  id: number
  photo?: Partial<GalleryPhoto>
}) {
  await axios.put(`/api/v1/gallery-photo/${id}/`, {
    ...photo,
  })
}
