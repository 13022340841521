import { ProjectID } from '@/types/platform'

export const modelsChannel = ({
  projectId,
  model,
  id,
}: {
  projectId: ProjectID
  model: string
  id: number
}): string => `project.${projectId}.models.${model}.${id}`

export const richHtmlBlockChannel = ({
  projectId,
  model,
  itemId,
}: {
  projectId: ProjectID
  model: string
  itemId: number
}): string => `project.${projectId}.richHtmlBlock.${model}.${itemId}`

export const blockingModelChannel = ({
  projectId,
  model,
  itemId,
}: {
  projectId: ProjectID
  model: string
  itemId: number
}): string => `project.${projectId}.blocking.${model}.${itemId}`

export const blockingRichHtmlBlockChannel = ({
  projectId,
  model,
  itemId,
}: {
  projectId: ProjectID
  model: string
  itemId: number
}): string => `project.${projectId}.blocking.richHtmlBlock.${model}.${itemId}`

export const stopWordsChannel = ({
  projectId,
  model,
  itemId,
  type,
}: {
  projectId: number | string
  model: string
  itemId: number
  type: string
}): string => `project.${projectId}.stopWords.${type}.${model}.${itemId}`

export const responseChannel = ({
  projectId,
  key,
}: {
  projectId: number | string
  key: number | string
}): string => `project.${projectId}.response.${key}`
