import axios from 'axios'
import { AuthInfo } from '@/types/auth'
import getBrowserName from '@/helpers/getBrowserName'
import fetchGeo from '../geo/fetchGeo'

export default async function login({
  email,
  password,
}: {
  password: string
  email: string
}): Promise<AuthInfo> {
  const { country_code: region } = await fetchGeo()

  const browser = getBrowserName()

  const { data } = await axios.post(
    '/api/v1/login/',
    {
      email,
      password,
      region,
      browser,
    },
    {
      autoLogout: false,
      useRetry: false,
      withAuthorizationHeader: false,
      authHostAsBaseUrl: true,
    }
  )

  return data
}
