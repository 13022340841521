import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { AwardsJury } from '@/types/awards'

export default async function fetchJury({
  cancelToken,
  ...params
}: ActionListPayload): Promise<ActionListResult<AwardsJury>> {
  const { data } = await axios.get<ActionListResult<AwardsJury>>(
    '/api/v1/story-jury/',
    {
      cancelToken,
      params,
    }
  )

  return data
}
