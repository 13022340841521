import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { UserModeration } from '@/types/userModeration'

export default async function fetchUsersModeration({
  projectId,
}: ActionListPayload): Promise<ActionListResult<UserModeration>> {
  const { data } = await axios.get('/api/v1/user/moderation/', {
    headers: projectId
      ? {
          'X-Project-ID': projectId,
        }
      : undefined,
    useRetry: false,
  })

  return data
}
