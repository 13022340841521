import { ActionItemResult } from '@/types/action'
import { ArticleAudio } from '@/types/article'
import axios from 'axios'

export default async function createArticleAudio({
  article_id,
  text,
}: {
  article_id: number
  text: string
}): Promise<ActionItemResult<ArticleAudio>> {
  const { data } = await axios.post(`/api/v1/article-audio/`, {
    article_id,
    text,
  })
  return data
}
