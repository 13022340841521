import axios from 'axios'
import forOwn from '@/helpers/forOwn'
import { SupportSubject, SubjectSlug } from '@/types/supportSubject'
import { ProjectID } from '@/types/platform'

const slugMap = new Map<string, SubjectSlug>([
  ['Проблема', SubjectSlug.ISSUE],
  ['Предложение', SubjectSlug.SUGGESTION],
  ['Доступ в административную панель', SubjectSlug.ACCESS_TO_ADMIN],
  ['Доступ к соцсетям', SubjectSlug.ACCESS_TO_NETWORKS],
  ['Доступ к парсеру', SubjectSlug.ACCESS_TO_PARSER],
  ['Доступ к Я.Метрике или GA', SubjectSlug.ACCESS_TO_METRICS],
  ['Другое', SubjectSlug.OTHER],
])

export default async function fetchSupportMeta({
  projectId,
}: {
  projectId: ProjectID
}) {
  const subjects: SupportSubject[] = []

  const {
    data: { projects },
  } = await axios.get<{ projects: Record<number, string> }>(
    '/api/v1/support/meta/',
    {
      headers: {
        'X-Project-ID': projectId,
      },
    }
  )

  forOwn(projects, (name, id) => {
    const slug = slugMap.get(name) || (id.toString() as SubjectSlug)

    if (!slug) {
      return
    }

    subjects.push(
      Object.freeze({
        slug,
        name,
        id: +id,
      })
    )
  })

  return subjects
}
