import { Session } from '@/types/sessiton'
import axios from 'axios'

export default async function fetchSessions(userId: number) {
  const { data } = await axios.get<Session[]>(
    `/api/v1/sessions/user/${userId}`,
    {
      autoLogout: false,
      useRetry: false,
      authHostAsBaseUrl: true,
    }
  )

  return data
}
