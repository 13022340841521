import * as Sentry from '@sentry/vue'
import axios, { AxiosError, AxiosRequestConfig, AxiosInstance } from 'axios'
import store from '@/store'
import { API_AUTH_ORIGIN, ENABLED_SENTRY } from '@/config/base'
import { getStorageItemWithExpiry } from '@/helpers/localStorageHelpers'
import { AuthInfo } from '@/types/auth'
import { UserGetters } from '@/store/modules/user/types'
import randomUUID from '@/helpers/randomUUID'

type RequestConfig = AxiosRequestConfig & {
  meta: {
    logLongRequestTimeoutId: number
    initedAt: string
  }
}

class ApiError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'ApiError'
  }
}

class LongRequestError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'LongRequestError'
  }
}

const logLongRequest = ({
  requestId,
  url,
  method,
  initedAt,
}: {
  requestId: string
  url?: string
  method?: string
  initedAt: string
}) => {
  if (url && method && ENABLED_SENTRY) {
    Sentry.captureException(
      new LongRequestError(
        JSON.stringify(
          {
            requestId,
            initedAt,
            url,
            method,
          },
          null,
          2
        )
      )
    )
  }
}

export const attachAuthInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.request.use((config) => {
    const customConfig = config as RequestConfig
    const projectId = store.getters.getProjectId
    const userId = (
      store.getters['user/getUserId'] as UserGetters['getUserId']
    )(projectId)

    const withAuthorizationHeader = customConfig.withAuthorizationHeader ?? true
    const authHostAsBaseUrl = customConfig.authHostAsBaseUrl ?? false

    const authInfo = withAuthorizationHeader
      ? getStorageItemWithExpiry<AuthInfo>('authInfo')
      : null

    if (authHostAsBaseUrl) {
      customConfig.baseURL = API_AUTH_ORIGIN
    }

    if (authInfo?.token && customConfig.headers) {
      const token = authInfo?.token

      customConfig.headers['Authorization'] = `Bearer ${token}`
    }

    customConfig.meta = customConfig.meta || {}

    const url = customConfig.url
    const method = customConfig.method
    const initedAt = new Date().toLocaleString('ru-RU', {
      timeZone: 'UTC',
      timeZoneName: 'short',
    })
    const requestId = `${projectId}_${userId}_${randomUUID()}`

    customConfig.meta.initedAt = initedAt

    customConfig.meta.logLongRequestTimeoutId = window.setTimeout(() => {
      if (customConfig.cancelToken?.reason) return

      logLongRequest({
        requestId,
        url,
        method,
        initedAt,
      })
    }, 10e3)

    if (projectId && customConfig.headers) {
      customConfig.headers['X-Project-ID'] = projectId
    }

    if (userId && customConfig.headers) {
      customConfig.headers['X-Project-User-ID'] = userId
    }

    if (config.headers) {
      config.headers['X-Request-ID'] = requestId
    }

    return customConfig
  })

  instance.interceptors.response.use(
    (response) => {
      const customConfig = response?.config as RequestConfig

      window.clearTimeout(customConfig?.meta?.logLongRequestTimeoutId)

      return response
    },
    (error: AxiosError) => {
      const customConfig = error?.config as RequestConfig | undefined
      const requestId =
        customConfig?.headers && customConfig?.headers['X-Request-ID']

      window.clearTimeout(customConfig?.meta?.logLongRequestTimeoutId)

      if (!axios.isCancel(error) && ENABLED_SENTRY) {
        Sentry.captureException(
          new ApiError(
            JSON.stringify(
              {
                requestId,
                initedAt: customConfig?.meta.initedAt,
                url: customConfig?.url,
                method: customConfig?.method,
                statusCode: error.response?.status,
                message: error.message,
              },
              null,
              2
            )
          )
        )
      }

      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (axios.isCancel(error)) {
        return Promise.reject(error)
      }

      const status = error.response?.status || 0
      const method = error.config.method?.toLowerCase()

      const autoLogout =
        (error.config.autoLogout ?? false) &&
        [401, 403].includes(status) &&
        method === 'get'

      if (autoLogout) {
        return store
          .dispatch('auth/logOut', { autoLogout: true })
          .finally(() => {
            return Promise.reject(error)
          })
      }

      return Promise.reject(error)
    }
  )
}
