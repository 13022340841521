import { ActionListPayload, ActionListResult } from '@/types/action'
import { MediaLibraryImageItem } from '@/types/mediaLibrary'
import axios from 'axios'

export default async function fetchMediaLibrary({
  page,
  sort,
  count,
  essenceId,
  cancelToken,
  essence,
}: Pick<ActionListPayload, 'cancelToken' | 'page' | 'count' | 'sort'> & {
  essence: string
  essenceId: number
}): Promise<ActionListResult<MediaLibraryImageItem>> {
  const essenceIdFilterKey = `${essence}-id`.split('-').join('_')

  const { data } = await axios.get(`/api/v1/${essence}-media-library/`, {
    cancelToken,
    params: {
      page,
      sort,
      count,
      filter: `${essenceIdFilterKey}=${essenceId}`,
      with: 'image',
    },
  })

  return data
}
