import axios from 'axios'

export default async function fetchGeo() {
  const { data } = await axios.get<{
    country_code: string
    country_name_en: string
    country_name_ru: string
    city_name_en: string
    city_name_ru: string
  }>('/__geo/')

  return data
}
