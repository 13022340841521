import axios from 'axios'
import { Article } from '@/types/article'

export default async function approvedAwardApplication({
  id,
}: {
  id: number
}): Promise<Article> {
  const { data } = await axios.post<Article>(
    `/api/v1/article-request/${id}/approve/`
  )
  return data
}
