import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { TopicInterest } from '@/types/topicInterest'

export default async function fetchTopicInterests({
  cancelToken,
  ...payload
}: ActionListPayload): Promise<ActionListResult<TopicInterest>> {
  const { data } = await axios.get('/api/v1/topic-interest/', {
    cancelToken,
    params: payload,
  })

  return data
}
