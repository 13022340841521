import axios from 'axios'

export default async function rejectUserModeration({
  id,
  projectId,
}: {
  id: number
  projectId: number
}) {
  await axios.post(
    `/api/v1/user/moderation/reject/${id}`,
    {},
    {
      headers: projectId
        ? {
            'X-Project-ID': projectId,
          }
        : undefined,
    }
  )
}
