import axios from 'axios'
import { ActionItemResult } from '@/types/action'
import { AwardsJury } from '@/types/awards'

export default async function fetchJury(
  id: number
): Promise<ActionItemResult<AwardsJury>> {
  const { data } = await axios.get(`/api/v1/story-jury/${id}`)

  return data
}
