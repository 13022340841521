import { GalleryEditData } from '@/types/gallery'
import axios from 'axios'

export default async function editGallery({
  id,
  gallery,
}: {
  id: number
  active?: number
  gallery?: GalleryEditData
}) {
  await axios.put(
    `/api/v1/gallery/${id}/`,
    {
      ...gallery,
    },
    {
      useRetry: true,
    }
  )
}
