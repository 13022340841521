import axios from 'axios'
import { ActionItemResult } from '@/types/action'
import { AwardsApplication } from '@/types/awards'

export default async function changeAwardApplication({
  id,
  ...payload
}: Partial<AwardsApplication>): Promise<ActionItemResult<AwardsApplication>> {
  const { data } = await axios.put(`/api/v1/article-request/${id}/`, payload)
  return data
}
