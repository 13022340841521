import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue, { PluginObject } from 'vue'
import colors from 'vuetify/lib/util/colors'
import ru from 'vuetify/src/locale/ru'
import Vuetify from 'vuetify/lib/framework'
import icons from './icons'

export const VuetifyPlugin: PluginObject<undefined> = {
  install() {
    Vue.use(Vuetify)
  },
}

const scrollBarWidth = 16

export const vuetify = new Vuetify({
  default: 'light',
  lang: { locales: { ru }, current: 'ru' },
  icons: {
    values: icons,
  },
  breakpoint: {
    thresholds: {
      md: 1280 + scrollBarWidth,
      lg: 1920 + scrollBarWidth,
    },
    scrollBarWidth,
    mobileBreakpoint: 1280,
  },
  theme: {
    themes: {
      light: {
        primary: colors.deepPurple,
        secondary: '#304156',
        success: colors.green,
        danger: colors.red,
        warning: colors.deepOrange,
        info: colors.indigo,
        dark: '#242939',
        background: '#f2f3f8',
      },
    },
  },
})
