import axios from 'axios'
import { ActionListPayload, ActionItemResult } from '@/types/action'
import { UserModeration } from '@/types/userModeration'

export default async function fetchUserModeration({
  id,
  projectId,
}: ActionListPayload): Promise<ActionItemResult<UserModeration>> {
  const { data } = await axios.get(`/api/v1/user/moderation/${id}`, {
    headers: projectId
      ? {
          'X-Project-ID': projectId,
        }
      : undefined,
    useRetry: false,
  })

  return data
}
