import { ActionItemResult } from '@/types/action'
import { ArticleAudio } from '@/types/article'
import axios from 'axios'

export default async function fetchArticleAudio({
  id,
}: {
  id: number
}): Promise<ActionItemResult<ArticleAudio>> {
  const { data } = await axios.get(`/api/v1/article-audio/${id}`)
  return data
}
